.layout {
  height: 100vh;
  height: calc(var(--mvh, 1vh) * 100);
  max-height: 854px !important;
  overflow: hidden;
  flex-direction: column !important;
  justify-content: space-between !important;
  padding-bottom: 8px;
  background-color: #f0f2f5 !important;

  :global {
    .ant-layout-content {
      display: flex !important;
      flex-direction: column;
      flex-shrink: 1 !important;
      flex-grow: 0 !important;
      flex-basis: 580px !important;
      justify-content: flex-end;
    }
  }
}

.background {
  height: 100vh;
  height: calc(var(--mvh, 1vh) * 100);
  background-color: #f0f2f5;
}

.container {
  width: 100%;
}

.candleLineWrapper {
  width: 100%;
  min-height: 116px;
  flex-grow: 1;
  flex-shrink: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #fff;
}

.candleLine {
  width: 100%;
  max-width: 512px;
  margin: 0 auto;
  padding-right: 30px;
  padding-bottom: 0;
}

.candleText {
  line-height: initial;
  color: black !important;
}

.guestProfile {
  position: relative;
  width: 100%;
  flex-grow: 0 !important;
  flex-shrink: 0 !important;
  flex-basis: 280px !important;
  text-align: center;
  background-color: #fff;

  &::after {
    content: '';
    position: absolute;
    top: calc(100% - 155px);
    height: 310px;
    left: -10px;
    width: calc(100vw + 20px);
    border-radius: 50%;
    z-index: 0;
    clip-path: polygon(0 50%, 100% 50%, 100% 110%, 0 110%);
    box-shadow: #d0cce1 0 4px 6px;
    background-color: #fff;
  }
}

.info {
  position: absolute;
  top: 0;
  height: 300px;
  left: calc(100vw / 2 - 150px);
  width: 300px;
  margin-top: 4px;
  padding-top: 16px;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  z-index: 5;
  background: linear-gradient(#fff, #fff) padding-box,
    linear-gradient(
        146deg,
        #6e00ff,
        #e4cfff,
        #6d00fe,
        #6b00fb,
        #f5e8ff,
        #8000ff,
        #ffffff,
        #7700ff,
        #a200ff
      )
      border-box;
  border: 2px solid transparent;

  h4 {
    margin-bottom: 0 !important;
    color: #503e97 !important;
  }
}

.infoItem {
  padding: 0 !important;
  margin: 0 !important;
  font-weight: bold;
  text-align: center;
  color: #503e97 !important;
}

.icons {
  position: absolute;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: black !important;
  transition: all 0.3s ease;
  z-index: 10;

  &:hover {
    transform: scale(1.1);
  }
}

.iconsCircles {
  top: 0px;
  left: -30px;
}

.iconsMedia {
  top: 0px;
  left: 240px;
}

.iconsMemories {
  top: 180px;
  left: 246px;
}

.iconsMusic {
  top: 180px;
  left: -36px;
}

.iconsTimeline {
  top: 264px;
  left: 108px;
}

.linkIcon {
  height: 50px;
}

.footer {
  padding: 0 !important;
  margin-top: 166px !important;
  flex-grow: 0 !important;
  flex-shrink: 0 !important;
  flex-basis: 52px !important;
  text-align: center;
  background-color: #f0f2f5 !important;
}
