.layout {
  min-height: 100vh;

  .config_panel_wrapper {
    padding: 16px;
  }

  :global {
    .ant-layout-sider {
      overflow: auto;
      height: 100vh;
      position: sticky;
      top: 0;
    }
    .ant-layout-content {
      padding-left: 16px;
      padding-right: 16px;
      padding-bottom: 16px;
    }
  }
}
