.container {
  max-height: 100vh;

  th {
    text-align: center !important;
  }

  .link {
    color: inherit;
  }

  .button {
    margin-bottom: 24px !important;
  }

  :global {
    .ant-page-header-heading-left {
      align-items: baseline;
    }
    .ant-tag {
      margin-bottom: 4px;
      margin-top: 4px;
      padding: 4px 8px;
      font-size: inherit;
    }
    .ant-input-wrapper {
      width: 208px;
    }
  }
}
