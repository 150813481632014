.container {
  text-align: center;

  :global {
    .ant-page-header-heading-left {
      align-items: baseline;
    }
    .ant-avatar {
      margin-bottom: 16px;
    }
    .ant-btn {
      border: none;
      padding: 0;
    }
    .anticon-more {
      font-size: 20px;
      vertical-align: top;
    }
  }
}
