.profile_card {
  width: 344px;

  .total {
    font-weight: bold;
  }

  :global {
    th {
      width: 128px;
    }
    .ant-btn {
      border: none;
      padding: 0;
    }
    .anticon-more {
      font-size: 20px;
      vertical-align: top;
    }
    .ant-avatar {
      margin-bottom: 16px;
    }
    .ant-col {
      text-align: left;
    }
  }
}
