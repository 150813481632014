.scheme_toggler {
  display: inline-flex;
  height: 22px;
  width: 50px;
  background-color: white;
  border-radius: 11px;

  :global {
    .ant-switch {
      height: 22px;
      width: 50px;
    }
  }
}
